<template>
    <div></div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        let self = this;
        console.log(self.$route.query.guid)
        this.whale.remote.getResult({
            url: "/api/School/ORG/Teacher/Login/" + self.$route.query.guid,
            // guid=61222B8F-8389-4A5C-BDEB-CD1830493980&mrl=mat/valid/act?for=SUB&tp=200
            // url:'/api/School/ORG/Teacher/Login/A1A2A300-0000-6666-5555-C5D79DD2A001',
            completed(m) {
                console.log('login', m)
                localStorage.setItem("loginToken", m.jwt.token);
                // self.whale.user.set(m.user);
                localStorage.setItem('userInfo', JSON.stringify(m.user))
                localStorage.setItem("tokenExpires", m.jwt.ExpiresLong);
                if (self.$route.query.mrl) {
                    self.$router.push(self.$route.query.mrl)
                } else {
                    self.$router.push("/")
                }
               
            }
        })
    },
}
</script>